import React, { useContext, useState } from "react"

const initialFormState = {
    invitedUserEmailAddress: '',
    inviteRedirectUrl: '',
    invitedUserType: 'guest',
    customizedMessageBody: '',
    inviteUserByADGroupName: { label: "Select", value: "select" },
    sendInvitationMessage: true,
    ccRecipients: '',
    invitedUserDisplayName: '',
    messageLanguage: '',
    employeeNumber: ''
}

const RegistrationContext = React.createContext();


const useRegistration = () => {

    return useContext(RegistrationContext);
}

const RegistrationProvider = ({ children }) => {



    const [formData, setFormData] = useState(initialFormState);
    const [apiResponse, setApiResponse] = useState({})

    const updateFromData = (payload) => {

        setFormData({ ...formData, ...payload });
    }

    const resetForm = () => {
        setFormData(initialFormState)
    }


    const updateApiResponse = (payload) => {

        setApiResponse({ ...apiResponse, ...payload });
    }

    return (

        <RegistrationContext.Provider value={{ formData, apiResponse, updateApiResponse, updateFromData, resetForm }}>
            {children}
        </RegistrationContext.Provider>

    );


}


export default RegistrationProvider;
export { useRegistration };