import React, {createContext, useContext} from 'react';

export const LayoutStateContext = createContext(null);

export function useLayoutState() {
  return useContext(LayoutStateContext);
}

export default function LayoutProvider({drawerOpen, drawerWidth, children }) {
  return (
    <LayoutStateContext.Provider value={{
      drawerOpen,
      drawerWidth,
    }}>
      {
        children
      }
    </LayoutStateContext.Provider>
  );
}