import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// import {SherwinLayout} from "./layouts"
import SherwinTheme from "./themes/SherwinTheme"
import RegistrationForm from './pages/RegistrationForm';


import SherwinLayout from "./layouts/SherwinLayout/Layout";
import ProfileContent from "./components/layout/ProfileContent";
import { Box } from "@mui/material"

import { DrawerContent } from "./components/layout/DrawerContent";

import { Outlet } from "react-router-dom";
import logo from "./assets/images/headerLogo.png"
import DialogProvider from './components/DialogProvider';
import Search from './pages/Search';
import "./App.css"



function App() {

  const drawerLinks = [{ text: "Search", link: "/search" }, { text: "Invitation", link: "/invitation" }];

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <SherwinTheme>
              <SherwinLayout
                title="B2B Invitation App "
                logo={logo}
                drawerContents={<DrawerContent items={drawerLinks} />}
                topRight={<ProfileContent />}

              >
                <DialogProvider>
                  <Box style={{ padding: "20px" }}>
                    <Outlet />
                  </Box>
                </DialogProvider>


              </SherwinLayout>
            </SherwinTheme>}>
            <Route index element={<Navigate to={"/search"} />} />
            <Route path='invitation' element={<RegistrationForm />} />
            <Route path="search" element={<Search />} />
            {/* <Route path="success" element={<RegistrationSuccess />} />
            <Route path="failed" element={< RegistrationFailure />} /> */}
            {/* <Route path="*" element={<NoPage />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;