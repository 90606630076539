import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Link from '@mui/material/Link';
import LayoutProvider from '../../context/Layout';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router';

const drawerWidth = 220;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function Layout({ drawerContents, topRight, title, logo, children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate()

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} elevation={4} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <ChevronRightIcon />
          </IconButton>

          <Stack onClick={() => { navigate("/") }} sx={{ mr: 5, cursor: 'pointer' }}>
            <img src={logo} alt="Sherwin Logo" />
          </Stack>


          <Typography
            sx={{ flexGrow: 1 }}
            variant="h6"
            noWrap
            component="div"
          >
            <Link color="inherit" underline="none" to="/">
              {title}
            </Link>
          </Typography>
          <HomeIcon onClick={()=>{navigate("/")}} fontSize='large' sx={{mr: 4, cursor: 'pointer'}}></HomeIcon>
          {topRight}
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            top: '64px'
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        elevation={16}
      >
        <DrawerHeader>
          {/* <Stack sx={{ width: '10em', padding: '32px 16px 8px' }}>
            <img src={logo} alt="Sherwin Logo" />
          </Stack> */}
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        {drawerContents}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <LayoutProvider drawerOpen={open} drawerWidth={drawerWidth}>
          {children}
        </LayoutProvider>
      </Main>
    </Box>
  );
}

export default Layout;