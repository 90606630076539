import { useEffect, useState } from "react"
import { useMsal, useAccount } from "@azure/msal-react"

export const useADGroups = () => {
    const [adList, setADList] = useState([]);
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0]);


    useEffect(() => {

        const decodeADGroupsFromClaims = async () => {

            const tokens = await instance.acquireTokenSilent({
                scopes: [process.env.REACT_APP_API_SCOPE], //Modify scope only when necessary
                account: account
            })
            const { idTokenClaims: { roles } } = tokens;
            console.debug(tokens.accessToken)
            console.debug(tokens.idToken)
            const defaultOption = { label: "Select", value: "select" }
            const options = roles.map(role => ({ label: role, value: role }))
            setADList([defaultOption, ...options]);
        }

        if (adList.length === 0)
            decodeADGroupsFromClaims()


    }, [instance, account,adList.length])

    return [adList];
}