import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';

export default function Account({
  userName,
  firstName,
  lastName,
  email,
  signInLabel,
  signOutLabel,
  onLogin,
  onLogout }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={userName || ''}>
          <Avatar sx={{ width: 32, height: 32 }}>{userName && userName.charAt(0)}</Avatar>
        </Tooltip>
        <Stack direction="column" sx={{ alignItems: 'flex-start' }}>
          <Typography sx={{ ml: 1 }} variant="subtitle1">{firstName} {lastName}</Typography>
          <Typography sx={{ ml: 1 }} variant="caption">{email}</Typography>
        </Stack>
        <IconButton
          onClick={handleClick}
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          color="inherit"
        >
          <KeyboardArrowDownIcon />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '320px',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ justifyContent: 'center'}}>
          <Stack direction="column" sx={{ alignItems: 'center' }}>
            <Tooltip title={userName || ''}>
              <Avatar sx={{ width: 32, height: 32 }}>{userName && userName.charAt(0)}</Avatar>
            </Tooltip>
            <Typography sx={{ ml: 1 }} variant="subtitle1" color="primary">{firstName} {lastName}</Typography>
            <Typography sx={{ ml: 1 }} variant="caption">{email}</Typography>
          </Stack>
        </MenuItem>
        <Divider />
        {
          userName ?
            <MenuItem onClick={onLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {signOutLabel}
            </MenuItem> :
            <MenuItem onClick={onLogin}>
              <ListItemIcon>
                <Login fontSize="small" />
              </ListItemIcon>
              {signInLabel}
            </MenuItem>
        }
      </Menu>
    </>
  );
}

