import { Box, styled, TextField, Button, Card, CardContent, Autocomplete } from "@mui/material"
import React, { useState } from "react"
import { SearchResults } from "../components/Search/SearchResults"
import { useADGroups } from "../hooks/useADGroups"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { useAccount, useMsal } from "@azure/msal-react"
import LoopIcon from '@mui/icons-material/Loop';



const SearchBox = styled("div")({

    // border: "2px solid orange",
    // padding: "25px"

})

const SearchInputBox = styled("div")({

    display: "flex",
    flexDirection: "column",
    alignItems: "center"
})

const SearchActionsBox = styled("div")({

    display: "flex",
    justifyContent: "flex-end",
    alignItems: 'center'
    // marginTop: "40px"

})

const SearchResultsBox = styled("div")({

    padding: "20px 0px",
    display: "flex",
    justifyContent: "center"

})

const RoundIcon = styled("div")(({ theme }) => ({
    borderRadius: "100%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1px",
    marginRight: "5px"
}))

const defaultAdGroup = { label: "Select", value: "select" }



export default function Search() {

    const [adGroup, setAdGroup] = useState(defaultAdGroup);
    const [adList] = useADGroups();
    const [userList, setUserList] = useState([])
    const navigate = useNavigate()
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0]);
    const [isLoading, setLoading] = useState(false)

    const onADChange = (event, value, reason) => {

        if (reason === "clear") {

            setAdGroup(defaultAdGroup)
            setUserList([])
        }

    }

    const updateUsers = (users) => {
        setUserList(users)
    }




    const handleSearch = async (adGroup) => {

        setLoading(true)



        if (adGroup.value === "select") {

            setLoading(false)
            return;

        }
        const params = new URLSearchParams()
        params.set('adGroupName', adGroup.value)


        const tokens = await instance.acquireTokenSilent({
            scopes: [process.env.REACT_APP_API_SCOPE], //Modify scope only when necessary
            account: account
        })

        let config = {
            method: 'get',

            url: `${process.env.REACT_APP_API_URL}/external/es/azure/invitation/groups/members?groupName=${adGroup.value}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tokens.accessToken
            },
            

        };
        try {
            axios.request(config).then((response) => {
                setUserList(response.data)
                setLoading(false)
            }).catch(err => {
                console.debug("Failed to fetch users:", err.toString())
                setUserList([])
                setLoading(false)

            });

        } catch (err) {

            console.debug("Failed to fetch users:", err.toString())
            setUserList([])
            setLoading(false)

        }




    }


    return (
        <>
            <Box>
                <SearchBox>
                    <Card>
                        <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <SearchInputBox>

                                {/* <TextField
                                    label="Email"
                                    variant="outlined"
                                    name="invitedUserEmailAddress"
                                    // value={formData.invitedUserEmailAddress}
                                    // onChange={handleChange}
                                    sx={{ width: "400px" }}
                                    margin="normal"
                                    // required
                                    type='email'
                                    onBlur={(e) => e.target.reportValidity()}
                                />
                                <Typography sx={{fontSize: "22px"}}>or</Typography> */}
                                <Autocomplete
                                    disablePortal
                                    sx={{
                                        width: "500px",
                                        marginLeft: '300px',
                                        '& .MuiAutocomplete-endAdornment': {
                                            display: "flex",
                                            flexDirection: 'row-reverse'
                                        }
                                    }}
                                    id="inviteUserByADGroupName"
                                    data-testid="ad-group"
                                    options={adList}
                                    value={adGroup}
                                    isOptionEqualToValue={(option, value) => {

                                        return option.value === value.value
                                    }
                                    }
                                    onInputChange={onADChange}
                                    onChange={(e, option) => {
                                        option && setAdGroup(option);
                                        option && handleSearch(option)
                                    }}

                                    renderInput={(params) => <TextField required {...params} label="User AD Group" />}
                                />
                            </SearchInputBox>
                            <SearchActionsBox>
                                <Box onClick={(e) => { handleSearch(adGroup) }} sx={{ cursor: "pointer", mr: 2 }}>
                                    <RoundIcon>
                                        <LoopIcon fontSize="large" sx={{ color: "white" }} />
                                    </RoundIcon>
                                    {/* <Typography>Refresh</Typography> */}

                                </Box>
                                {/* {isLoading ? <CircularProgress style={{ marginRight: "20px" }} /> :
                                    <Button onClick={() => { handleSearch() }} data-testid="search-btn" style={{ marginRight: "20px" }} variant="contained" color="success">
                                        Search
                                    </Button>} */}
                                <Button onClick={() => { onADChange(null, null, "clear"); setUserList([]) }} style={{ marginRight: "20px" }} variant="outlined" color="primary">
                                    Clear
                                </Button>
                                <Button onClick={() => { navigate("/invitation") }} style={{ marginRight: "20px" }} variant="outlined" color="primary">
                                    Invite Users
                                </Button>
                            </SearchActionsBox>
                        </CardContent>
                    </Card>

                </SearchBox>
                <SearchResultsBox>
                    <SearchResults isLoading={isLoading} updateUsers={updateUsers} currentAdGroup={adGroup} userList={userList} />
                </SearchResultsBox>

            </Box>
        </>
    )
}