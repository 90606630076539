import React, { useEffect, useState } from 'react';
import { Divider, List, ListItemButton, ListItemText } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom";

function DrawerContent({ items = [] }) {
    const [active, setActive] = useState('');
    const navigate = useNavigate();
    const { pathname } = useLocation()

    useEffect(() => {
        items.forEach((item) => {
            if (item.link === pathname) {
                setActive(item.text)
            }

        })
    }, [pathname, items])



    return (
        <List>

            <Divider sx={{ paddingBottom: '16px', marginBottom: '16px' }} />
            {items.map((item, index) => (


                <ListItemButton key={index} selected={item.text === active} onClick={(e) => { setActive(item.text); navigate(item.link) }} >

                    <ListItemText primary={item.text} />
                </ListItemButton>

            ))}
        </List>
    )
}

export { DrawerContent }

