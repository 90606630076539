import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Box from '@mui/material/Box';
import Button from "@mui/material/Button"
import { DataGridPremium } from '@mui/x-data-grid-premium/DataGridPremium';
import { CircularProgress, Typography } from "@mui/material";
import { useAccount, useMsal } from "@azure/msal-react"
import axios from "axios"
import { LicenseInfo } from '@mui/x-data-grid-premium';
import { useDialog } from "../DialogProvider";
import ErrorIcon from '@mui/icons-material/Error';
import { useLayoutState } from "../../context/Layout";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)
const OFFSET = 40;


export function SearchResults({ userList, currentAdGroup, updateUsers, isLoading }) {

    const [rows, setRows] = useState([])
    const [currentDeleteId, setCurrentDeleteId] = useState(null);
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0]);
    const { updateDialog, dialogProps } = useDialog();
    const {open}=dialogProps
    const { drawerOpen, drawerWidth } = useLayoutState();
    const dataGridContainerRef = useRef(null);

    // console.debug("AD: ", currentAdGroup)

    useEffect(() => {
        const handleResize = () => {
            const wWidth = window.innerWidth;
            const gridWidth = drawerOpen
                ? wWidth - drawerWidth - OFFSET
                : wWidth - OFFSET;
            dataGridContainerRef.current.style.width = `${gridWidth}px`;
        };
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [drawerOpen, drawerWidth]);

    useEffect(() => {

        setRows(getUsersAsRows(userList))

    }, [userList])

    const getUsersAsRows = (userList) => userList.map((row, index) => ({ id: index, userId: row.id, email: row.email, firstName: row.firstName, lastName: row.lastName, displayName: row.displayName, userType: row.userType, delete: "delete" }))
    const deleteUser = useCallback(async (email) => {

        const tokens = await instance.acquireTokenSilent({
            scopes: [process.env.REACT_APP_API_SCOPE], //Modify scope only when necessary
            account: account
        })

        let config = {
            method: 'post',

            url: `${process.env.REACT_APP_API_URL}/external/es/azure/invitation/removeinviteduser`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + tokens.accessToken
            },
            data:
            {
                "removeInvitedGuestUserEmail": email,
                "invitedUserAzureADGroup": "",
                "invitedUserAzureADGroupName": currentAdGroup.value
            }

        };
        try {
            await axios.request(config);
            updateUsers(userList.filter(user => email !== user.email))
            // setDeleting(false)


        } catch (err) {

            console.debug("Failed to delete user:", err.toString())

            // setDeleting(false)

        }


    }, [userList, currentAdGroup.value, account, instance, updateUsers])

    const onDelete = useCallback((params) => {

    
        setCurrentDeleteId(params.row.userId);
        updateDialog({
            open: true,
            title: <div ><Typography style={{ display: 'flex', alignItems: 'center' }} variant='h6'>Remove user <ErrorIcon sx={{ marginLeft: '10px' }} color="error"></ErrorIcon></Typography></div>,
            content: <Typography>Are you sure want to remove user <strong>{params.row.email}</strong> from user AD group <strong>{currentAdGroup.value}</strong> ?</Typography>,
            actions: <><Button onClick={() => { updateDialog({ open: false }); }} >Cancel</Button>
                <Button data-testid={"continue-delete"} onClick={() => { updateDialog({ open: false }); deleteUser(params.row.email); }} autoFocus>
                    Continue
                </Button></>
            
        })
        // deleteUser(params.row.email);
    }, [deleteUser, updateDialog, currentAdGroup])





    const columns = useMemo(() => {
        return [
            // { field: 'id', headerName: 'ID', width: 50, },
            {
                field: 'email',
                headerName: 'Email',
                width: 300,
                editable: true,

                resizable: true
            },
            {
                field: 'displayName',
                headerName: 'Display Name',
                width: 200,
                editable: true,
                resizable: true

            },
            {
                field: 'firstName',
                headerName: 'First Name',
                width: 150,
                editable: true,
                renderCell: (params) => {
                    return params.value ? params.value : <>-</>
                }

            },
            {
                field: 'lastName',
                headerName: 'Last Name',
                width: 150,
                editable: true,
                renderCell: (params) => {
                    return params.value ? params.value : <>-</>
                }

            },
            {
                field: 'userType',
                headerName: 'User Type',
                description: 'This column has a value getter and is not sortable.',
                sortable: false,
                width: 150,
                flex: 1

            },
            {
                field: 'delete',
                headerName: '',
                width: 150,
                editable: false,
                flex: 1,
                renderCell: (params) => {
                    return (open && currentDeleteId === params.row.userId ? <CircularProgress /> :
                        <Button data-testid={params.row.email} onClick={(e) => { onDelete(params) }} style={{ width: "1px", textTransform: "none", height: "20px" }} variant="contained" color="primary">
                            Remove
                        </Button>
                    )
                }

            },
        ];

    }, [open, currentDeleteId, onDelete])

    // console.debug("Rows: ",rows)
    // console.debug("Columns: ",columns)

    return (
        <Box
            sx={{ height: 590 }}
            ref={dataGridContainerRef}
        >
            <DataGridPremium
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pagination
                pageSizeOptions={[10, 50, 100]}
                // checkboxSelection
                disableRowSelectionOnClick
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: "#003DA5",
                        color: '#ffffff'
                    }
                }}
                loading={isLoading}
                columnBuffer={Number.MAX_SAFE_INTEGER}
                localeText={{ noRowsLabel: 'No Users Found' }}
            />
        </Box>
    )
}