import React from "react";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import themeOptions from "./theme";


export default function SherwinTheme({ children }) {
    const theme = createTheme(themeOptions);

   


    return (
        <ThemeProvider theme={responsiveFontSizes(theme)}>
            {children}
        </ThemeProvider>
    );
}