const theme = {
    palette: {
      mode: 'light',
      primary: {
        light: '#5E89D2',
        main: '#003DA5',
        dark: '#062F76',
        contrastText: '#ffffff',
      },
      secondary: {
        light: '#BA68C8',
        main: '#9C27B0',
        dark: '#7B1FA2',
        contrastText: '#000000',
      },
    },
    components: {
      MuiAccordionSummary: {
        styleOverrides: {
          root: {
            backgroundColor: '#1976D214',
          },
        },
      },
    },
  
  };
  export default theme;