import * as React from 'react';

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DialogActions } from '@mui/material';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const DialogContext = React.createContext();

const useDialog = () => {

    return React.useContext(DialogContext);

}

const initialState = {

    open: false,
    title: "",
    content: null,
    onClose: ()=>{}
}


export default function DialogProvider({ children }) {

    const [dialogProps, setDialogProps] = React.useState(initialState);


    const handleClose = () => {
        setDialogProps({ ...dialogProps, ...{ open: false } })
    };


    const updateDialog = (payload) => {

        setDialogProps({ ...dialogProps, ...payload })
    }



    return (
        <div>
            <DialogContext.Provider value={{ dialogProps, updateDialog }}>

                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={dialogProps.open}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        {dialogProps.title}
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        {dialogProps.content}
                    </DialogContent>
                    <DialogActions>
                        {dialogProps.actions}
                    </DialogActions>
                </BootstrapDialog>
                {children}
            </DialogContext.Provider>
        </div>
    );
}

export { useDialog }