import React from "react"
import { useRegistration } from "../context";
import { Box } from "@mui/material"



const Success = (props) => {

    const { apiResponse } = useRegistration()
    const parsedResponse = apiResponse.response ? JSON.parse(apiResponse.response) : null


    return (
        <Box>
          
            <table style={{ width: "100%"}}>

                {/* <tr>
                    <td><strong>User Id </strong></td>
                    <td>{parsedResponse?.invitedUserId}</td>
                </tr> */}
                <tr>
                    <td><strong>Email Address </strong></td>
                    <td>{parsedResponse?.invitedUserEmailAddress}</td>
                </tr>
                <tr>
                    <td><strong>Application URL </strong></td>
                    <td><a href={parsedResponse?.inviteRedirectUrl}  >{parsedResponse?.inviteRedirectUrl}</a></td>
                </tr>
                <tr>
                    <td><strong>User Type </strong></td>
                    <td>{parsedResponse?.invitedUserType}</td>
                </tr>
                {/* <tr>
                    <td><strong>AD Group </strong></td>
                    <td>{parsedResponse?.invitedUserADGroup}</td>
                </tr> */}
                <tr>
                    <td><strong>AD Group Name </strong></td>
                    <td>{parsedResponse?.invitedUserADGroupName}</td>
                </tr>
                <tr>
                    <td><strong>Status </strong></td>
                    <td>{parsedResponse?.status}</td>
                </tr>
                <tr>
                    <td><strong>Redeem Invitation </strong></td>
                    <td><a href={parsedResponse?.inviteRedeemUrl}  >click here</a></td>
                </tr>

            </table>
        </Box>
    )
}


export default Success;