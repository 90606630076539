import React from "react"
import { useRegistration } from "../context";

const RegistrationFailure = (props) => {

    const { apiResponse } = useRegistration()

    return (
        <div>

            <p><strong>Status: </strong> {apiResponse.status ?? null}</p>
            <p><strong>Description: </strong> {apiResponse.response ?? null}</p>

        </div>
    )
}


export default RegistrationFailure;