import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import { RegistrationProvider } from './context';
import { LicenseInfo } from '@mui/x-data-grid-premium';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)

const msalInstance = new PublicClientApplication(msalConfig);
const authRequest = {
  ...loginRequest,
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <RegistrationProvider>
          <App />
        </RegistrationProvider>

      </MsalAuthenticationTemplate>
    </MsalProvider>
  </React.StrictMode>
);


// root.render(

//           <App />

//   );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
