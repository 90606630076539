import React from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';

import  Account  from  '../Account';

const ProfileContent = () => {
    //const isAuthenticated = useIsAuthenticated()
    const { accounts, instance } = useMsal();
    // const [accessToken, setAccessToken] = useState(null);
    //const [open, setOpen] = useState(false);

    //const handleClick = () => {
      //setOpen(!open);
    //};

    function handleLogout(i) {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
       
    }

    function handleLogin(i) {
        i.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

   

    const name = accounts[0] && accounts[0].name;
    const email = accounts[0] && accounts[0].username;

    return (
      
        <Account 
            firstName={name}
            email={email}
            userName={email}
            signOutLabel={'Sign Out'} 
            signInLabel={'Sign In'}
            onLogin={handleLogin}
            onLogout={handleLogout}  
        />

    );
}

export default ProfileContent